import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";

interface HotelExtraInfoModalContextType {
    isOpen: boolean,
    openExtraInfo: (m: any, v: any) => void,
    closeExtraInfo: () => void
}

import { useOnClickOutside } from "../hooks";
import { ExtraInfo } from "../components";

const HotelExtraInfoModalContext = createContext<HotelExtraInfoModalContextType | undefined>(undefined);

export const HotelExtraInfoProvider = ({ children }) => {
    const ref = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [info, setInfo] = useState({});
    const [styleObj, setStyleObj] = useState({});

    const openExtraInfo = useCallback((minfo, mposition) => {
        const w = screen.width; //180
        const h = screen.height;    //400

        const right = w - (mposition.x + 250);
        const bottom = h - (mposition.y + 400);

        let sv: any = { top: `${Math.floor(mposition.y) + 10}px` };
        let sh: any = { left: `${Math.floor(mposition.x) + 10}px`, };

        if (bottom <= 0)
            sv = { bottom: `${h - Math.floor(mposition.y) - 10}px` };
        if (right <= 0)
            sh = { right: `${w - Math.floor(mposition.x) - 10}px` };

        setInfo(minfo);
        setStyleObj({ ...sv, ...sh });
        setIsOpen(true);
    }, []);

    const closeExtraInfo = useCallback(() => setIsOpen(false), []);
    useOnClickOutside(ref, closeExtraInfo);

    const value = useMemo(
        () => ({ isOpen, openExtraInfo, closeExtraInfo, }),
        [closeExtraInfo, isOpen, openExtraInfo]
    );

    return (
        <HotelExtraInfoModalContext.Provider value={value}>
            {children}
            {
                isOpen &&
                <div className={`w-max fixed z-20 sm:block hidden max-h-[400px] overflow-y-scroll`} style={styleObj} ref={ref}>
                    <ExtraInfo info={info} />
                </div>
            }

            {
                isOpen &&
                <div className={`w-screen h-screen left-0 top-0 fixed z-20 sm:hidden flex justify-center items-center`} style={{ backgroundColor: 'rgba(0,0,0, 0.7)' }}>
                    <div className={`w-max max-h-[400px] overflow-y-scroll`} ref={ref}>
                        <ExtraInfo info={info} />
                    </div>
                </div>
            }
        </HotelExtraInfoModalContext.Provider>
    );
};

export const useExtraInfoTooltip = () => useContext<HotelExtraInfoModalContextType>(HotelExtraInfoModalContext);
