import React, { useEffect, useState } from "react";
import { useSocketContext } from "../../providers";
import { TwinAppEvent } from "@twin-shared";
import { Paper } from "@mui/material";
import { ReactComponent as ArrowRotateIcon } from "@twin-shared/lib/assets/arrowRotate.svg";
import "./styles.css";

export const ServerState = () => {
  const { isOnline, reconnect } = useSocketContext();
  const [reconnecting, setReconnecting] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener(TwinAppEvent.onReconnecting, () => {
      setError(false);
      setReconnecting(true);
    });
    window.addEventListener(TwinAppEvent.onConnecting, () => {
      setError(false);
      setReconnecting(true);
    });
    window.addEventListener(TwinAppEvent.onConnectionError, () => {
      setError(true);
      setReconnecting(false);
    });
    return () => {
      window.removeEventListener(TwinAppEvent.onReconnecting, this);
      window.removeEventListener(TwinAppEvent.onConnecting, this);
      window.removeEventListener(TwinAppEvent.onConnectionError, this);
      setReconnecting(false);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setTimeout(
        () => window.dispatchEvent(new CustomEvent(TwinAppEvent.onScrollToEnd)),
        200
      );
    }

    if (isOnline) {
      setReconnecting(false);
    }
  }, [isOnline]);
  const renderMessage = () => {
    if (error) return "Connection was lost...";

    if (reconnecting) return "Connecting to server...";

    return "Disconnect server...";
  };

  if (isOnline) return <></>;

  return (
    <Paper
      className={`fixed items-center !bg-transparent !shadow-none px-1/2 py-1 w-full border-none ${isOnline ? "bottom-[87px]" : "bottom-[100px]"} !text-lg flex lg:mx-HORIZONTAL_MARGIN md:mx-[10%] mx-[5%] z-[1] left-0 right-0 `}
    >
      {renderMessage()}
      <div
        className={`cursor-pointer ${reconnecting ? "animate-spin" : ""} ml-3`}
        onClick={() => reconnect()}
        data-testid="test-id"
      >
        <ArrowRotateIcon className="opacity-70" />
      </div>
    </Paper>
  );
};
