import React from 'react';
import {YourCart, YourList} from '../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

export const CartList = () => {
  const {cart, list} = useSelector((state: RootState) => state.cartList);
  
  return (
    <div style={{zIndex: 1}}>
      {cart.action === 'show' && <YourCart component={cart} />}
      {list.action === 'show' && <YourList component={list} />}
    </div>
  );
};
