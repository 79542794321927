import { useState } from "react";
import { Collapse } from "react-collapse";
import { AntdIcon } from "../AntdIcon";

export const Dropdown = ({
  component,
  render,
}: {
  component: any;
  render: (any) => any;
}) => {
  const header = component.header;
  const content = component.content;
  if (!header || !content) return <></>;
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ ...component.ui_args?.web }} className="flex justify-between">
      <div className="pl-[5px] flex-grow">
        <div onClick={() => setExpanded(!expanded)}>{render(header)}</div>
        <Collapse isOpened={expanded}>
          <div>{render(content)}</div>
        </Collapse>
      </div>

      <div className="text-gray-600">
        <div onClick={() => setExpanded(!expanded)} className="cursor-pointer">
          <AntdIcon
            type={expanded ? `Up` : `Down`}
            style={{ width: "85%" }}
            className="text-black"
          />
        </div>
      </div>
    </div>
  );
};
