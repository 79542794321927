import {createSlice} from '@reduxjs/toolkit';
import {ComponentTypes} from '@twin-shared';

export interface CartListState {
  cart: any;
  list: any;
}

const initialState: CartListState = {
  cart: {},
  list: {},
};

export const cartListSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    updateCartList: (state, action) => {
      const {components, messageId} = action.payload;
      components.forEach(
        (child: {[x: string]: any; type: ComponentTypes; action: string}) => {
          if (
            child?.type === ComponentTypes.YourCart ||
            child?.type === ComponentTypes.YourList
          ) {
            if (child.action === 'show') {
              child['message_id'] = messageId;
              if (child?.type === ComponentTypes.YourCart) {
                state.cart = child;
              } else if (child?.type === ComponentTypes.YourList) {
                state.list = child;
              }
            } else if (child.action === 'hide') {
              if (child?.type === ComponentTypes.YourCart) {
                state.cart = {};
              } else if (child?.type === ComponentTypes.YourList) {
                state.list = {};
              }
            }
          }
        },
      );
    },
  },
});

export const {updateCartList} = cartListSlice.actions;
export default cartListSlice.reducer;
