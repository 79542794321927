import React from "react";

interface UnknownProps {
  component: any;
}

export const Unknown = (props: UnknownProps) => {
  const { component } = props;
  return (
    <div
      className="bg-red-500 text-white p-4 text-base"
      key={`div-${component.id}`}
    >
      {JSON.stringify(component, null, 2)}
    </div>
  );
};
