import {StreamRecorder, WavConfig} from '@twin-shared'
import { Options, RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';

export class WebRecorder extends StreamRecorder<ArrayBuffer> {
  private _stream: MediaStream | null = null;
  private _recorder: RecordRTCPromisesHandler | null = null;
  private seqNo = 1;

  async init(): Promise<void> {
    this._stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
  }

  async _start() {
    this._recorder = new RecordRTCPromisesHandler(this._stream!, this._config);
    await this._recorder.startRecording();
  }

  async _stop() {
    this._isRecording = false;
    await new Promise(resolve => setTimeout(resolve, this.chunkDuration * 1000));
    await this._recorder!.stopRecording();
  }

  async _onBlob(blob: Blob, seqNo: number) {
    const buffer = await blob.arrayBuffer();    
    this.onChunk(buffer, seqNo);
  }

  get _config(): Options {
    return {
      type: 'audio',
      mimeType: 'audio/wav',
      disableLogs: true,
      timeSlice: this.chunkDuration * 1000,
      recorderType: StereoAudioRecorder,
      audioBitsPerSecond: 512_000,
      desiredSampRate: WavConfig.sampleRate,
      numberOfAudioChannels: WavConfig.channels as 1 | 2,
      ondataavailable: async (blob: Blob) => {
        this._onBlob(blob, this.seqNo++);
      },
    };
  };
}