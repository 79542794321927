import React from "react";
import { Select } from "antd";

interface SelectInputProps {
  data: any;
  component: any;
  args: any;
  allowMultiSelect: boolean;
  onChange: (component: any, value: any) => void;
}

export const SelectInput = (props: SelectInputProps) => {
  const {
    data,
    component,
    args,
    allowMultiSelect = false,
    onChange,
    ...rest
  } = props;
  return (
    <Select
      className="bg-white"
      placeholder={component.placeholder}
      value={data[component.id]}
      mode={allowMultiSelect ? "multiple" : undefined}
      onChange={(value) => onChange(component, value)}
      {...args}
      {...rest}
    >
      {component.options.map((option: any) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
