import React from "react";
import { InputNumber } from "antd";

interface NumberInputProps {
  data: any;
  component: any;
  onChange: (component: any, value: any) => void;
  args?: any;
}

export const NumberInput = (props: NumberInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  return (
    <InputNumber
      min={component.validation?.min ?? 0}
      max={component.validation?.max}
      step={component.step ? component.step : 1}
      placeholder={component.placeholder}
      value={data[component.id]}
      onChange={(value) => onChange(component, value)}
      {...args}
      {...rest}
      className="h-9 flex items-center"
      style={{ minWidth: "100%" }}
    />
  );
};
