import { Button } from "antd";
import { ReactComponent as SendIcon } from "@twin-shared/lib/assets/send.svg";

export const SubmitButton = ({ component }: { component: any }) => {
    const backgroundColor = component?.backgroundColor || '#148898';
    const textColor = component?.textColor || 'white';
    return (
        <Button
            key={component.id}
            htmlType="submit"
            className="rounded-[5px] max-[28px] h-[27px]"
            style={{
                backgroundColor: backgroundColor || '#FFEF9F',
                color: textColor || '#A1865E',
            }}
            {...component.ui_args?.web}
        >
            {component.label ? component.label : <SendIcon className="send-icon" fill={textColor} width={'16px'} height={'16px'} />}
        </Button>
    );
}