import React from "react";
import { Input } from "antd";

interface TextInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TextInput = (props: TextInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  return (
    <Input
      placeholder={component.placeholder}
      value={data[component.id]}
      onChange={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
      className="!h-9"
      key={`TextInput-${component.id}`}
    />
  );
};
