import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  authToken: string;
  sessionStartTime: number;
  lastActivityTime: number;
  appSetting: any;
}

const initialState: AuthState = {
  authToken: "",
  sessionStartTime: 0,
  lastActivityTime: 0,
  appSetting: {},
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setSessionStartTime: (state, action) => {
      state.sessionStartTime = action.payload;
      state.lastActivityTime = action.payload;
    },
    clearSession: (state) => {
      state.sessionStartTime = 0;
      state.lastActivityTime = 0;
    },
    updateSessionTime: (state, action) => {
      state.lastActivityTime = action.payload;
    },
    setAppSetting: (state, action) => {
      state.appSetting = action.payload;
    },
  },
});

export const {
  setAuthToken,
  setSessionStartTime,
  clearSession,
  updateSessionTime,
  setAppSetting,
} = appSlice.actions;
export default appSlice.reducer;
