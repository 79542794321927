import React from "react"

interface HotelSummaryProps {
    photo: string,
    rating: number | string,
    reviews: string,
    price: string,
    price_base: string,
    location: string,
    hotel_name: string,
    highlights: []
}


export const HotelSummary = ({ component }: { component: any }) => {
    const args: HotelSummaryProps = component.args;

    return (
        <div className="p-[5px] font-calibri min-w-max" style={component.ui_args}>
            <div className="flex gap-[10px]">
                <div className="w-[120px] h-[90px] flex items-center justify-center overflow-hidden">
                    <img src={args.photo} />
                </div>

                <div className="flex-grow">
                    <div className="flex gap-[5px] justify-between">
                        <div>
                            <div className="bg-[#555] text-white px-[5px] py-[2px] w-fit border border-gray-300 rounded-[3px]"> {args.rating} </div>
                            <div className="text-[10px] text-gray-600">{args.reviews}</div>
                        </div>

                        <div>
                            <div className="text-black text-[14px] text-right font-medium">{args.price}</div>
                            <div className="text-[10px] text-gray-600 text-right">{args.price_base}</div>
                        </div>
                    </div>
                    <div className="text-[12px] text-gray-600">
                        {args.location}
                    </div>
                </div>
            </div>

            <div className="text-[18px] font-medium leading-[20px] max-w-[250px]">
                {args.hotel_name}
            </div>

            <div className="text-[12px] text-gray-600">
                {args.highlights.join(' - ')}
            </div>
        </div>
    )
}