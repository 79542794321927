import React, { useEffect, useRef, useState } from "react";
import { AntdIcon } from "../AntdIcon";
import { useExtraInfoTooltip } from "../../providers";
interface HotelRoomInterface {
  photo: string;
  price: string;
  price_base: string;
  beds: [];
  sleeps: string;
  room_name: string;
  extra_info: any;
}

export const HotelRoom = ({ component }: { component: any }) => {
  const ref = useRef(null);
  const { openExtraInfo } = useExtraInfoTooltip();

  const args: HotelRoomInterface = component.args;
  const bedstr = args.beds.join();

  const onIconClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (ref) {
      openExtraInfo(args.extra_info, ref.current.getBoundingClientRect());
    }
  };

  return (
    <div className="p-[5px] font-calibri min-w-max" style={component.ui_args}>
      <div className="flex gap-[10px]">
        <div className="w-[120px] h-[90px] flex items-center justify-center overflow-hidden">
          <img src={args.photo} />
        </div>

        <div className="flex-grow">
          <div className="flex gap-[5px] justify-between">
            <div
              className="relative cursor-pointer"
              ref={ref}
              onClick={onIconClicked}
            >
              <AntdIcon type={`InfoCircle`} className={"text-[20px]"} />
            </div>

            <div>
              <div className="text-black text-[14px] text-right font-medium">
                {args.price || ""}
              </div>
              <div className="text-[10px] text-gray-600 text-right">
                {args.price_base || ""}
              </div>
            </div>
          </div>
          <div>
            <div className="text-[16px] text-black">{bedstr || ""}</div>
            <div className="text-[12px] text-gray-600">{args.sleeps || ""}</div>
          </div>
        </div>
      </div>

      <div className="text-[18px] font-medium leading-[20px] max-w-[250px]">
        {args.room_name}
      </div>
    </div>
  );
};
