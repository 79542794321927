import { useEffect } from "react";

export const useOnClickOutside = (ref, onOutsideClick) => {
    useEffect(() => {
      const listener = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick();
        }
      };

      try {
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
      } catch (err) {
        console.log(err);
      }
    
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, onOutsideClick]);
  };
  