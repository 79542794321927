import React from "react";

export const AntdIcon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const name = type
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");
  const Component =
    icons[`${name}Outlined`] ||
    icons[`${name}Filled`] ||
    icons[`${name}TwoTone`];
  if (!Component) {
    return null;
  }
  return <Component {...rest} />;
};
