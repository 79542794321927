import "./styles.css";

export const PulseCircle = ({ color, style, ...properties }) => {
  return (
    <div
      className={`circle pulse`}
      style={{ background: color, ...style }}
      {...properties}
      data-testid="test" 
    >
      {properties.children}
    </div>
  );
};
