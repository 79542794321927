import { Button } from "antd";
import { useSocketContext } from "../../providers";

const defaultstyleClass = "!rounded-[5px]";

export const ActionButton = ({ messageid, component, data }: { messageid: string, component: any, data: any }) => {
    const {sendUiComponentAction} = useSocketContext();

    const backgroundColor = component?.backgroundColor || '#148898';
    const textColor = component?.textColor || 'white';

    return (
        <Button
            key={component.id}
            onClick={() => sendUiComponentAction(messageid, component.id, component.action, data)}
            style={{
                backgroundColor: backgroundColor || '#FFEF9F',
                color: textColor || '#A1865E'
            }}
            className={`${defaultstyleClass} rounded-[5px]`}
        >
            {component.label}
        </Button>
    );
}