import { CombinedContextProvider } from "./providers";
import { AppNavigator } from "./navigation/AppNavigator";

function App() {
  return (
    <CombinedContextProvider>
      <AppNavigator />
    </CombinedContextProvider>
  );
}

export default App;
