import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { ReactComponent as UploadFileIcon } from "@twin-shared/lib/assets/uploadfileicon.svg";
import { randomId } from "@twin-shared";

interface FileInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const FileInput = (props: FileInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  const [files, setFiles] = useState(data[component.id] ?? []);

  useEffect(() => {
    if (files.length > 0) {
      onChange(component, files);
    }
  }, [files]);

  const readFile = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        resolve({
          content: event.target?.result,
          metadata: {
            name: file.name,
            size: file.size,
            type: file.type,
            thumbUrl: URL.createObjectURL(file),
            uid: randomId(),
          },
        });
      };
      reader.onerror = (event) => {
        reject(event.target.error);
      };
      reader.readAsArrayBuffer(file); // change this if you want to read the file as e.g. text
    });
  };

  const onFileUpload = async ({ onError, onSuccess, file }: any) => {
    setTimeout(
      async () => {
        try {
          const fileObj = await readFile(file);
          setFiles((prev) => [...prev, fileObj]);
          onSuccess(file);
        } catch (err) {
          onError(err);
        }
      },
      Math.floor(Math.random() * 1000)
    );
  };

  return (
    <Upload
      multiple={true}
      name={component.id}
      customRequest={(req) => onFileUpload(req)}
      fileList={(data[component.id] ?? []).map((file) => ({
        name: file.metadata.name,
        thumbUrl: file.metadata.thumbUrl,
        showRemove: true,
        uid: file.metadata.uid,
      }))}
      onRemove={(file) => {
        const _files = files.filter((a) => a.metadata.uid !== file.uid);
        setFiles(_files);
        onChange(component, _files);
      }}
      {...args}
      {...rest}
      className="flex items-center rounded-[5px]"
      data-testid="file-upload"
    >
      <UploadFileIcon
        className="cursor-pointer max-h-[25px]"
        data-testid="upload-file-icon"
      />
    </Upload>
  );
};
