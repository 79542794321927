import { useEffect, useState } from "react";
import { useSocketContext } from "../providers";
import { WebRecorder } from "./WebRecorder";

let recorder: WebRecorder | null = null;

export const useRecording = (voiceDurationInSeconds: number, messageLabel: string)  => {
  const [isMount, setIsMount] = useState<boolean>(false);
  const {sendVoice, sendVoiceEnd} = useSocketContext();

  const init = async () => {
    const onChunk = (buffer: ArrayBuffer, seqNo: number) => {
      console.log(
        `Sending voice chunk ${seqNo} to server. Buffer length: ${buffer.byteLength}.`,
      );
      sendVoice(buffer, seqNo);
    }
    try{
      recorder = new WebRecorder(voiceDurationInSeconds, onChunk);
      await recorder.init();
      return recorder;
    }catch(ex){
      console.log("Error in useRecording: ", ex);
    }
    return null;
  };

  const checkPermission = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const browserAvailable = detectBrowserInfo();
        if (!browserAvailable) {
          reject("This browser isn't available.");
          return;
        }
        if ("MediaRecorder" in window) {
          // Open bug in Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1609427
          const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
          if (isFirefox) {
            resolve("granted");
            return;
          }

          const result = await navigator.permissions.query({
            // @ts-ignore
            name: "microphone",
          });
          resolve(result.state);
        } else {
          console.log(
            "The MediaRecorder API is not supported in your browser.",
          );
          reject("The MediaRecorder API is not supported in your browser.");
        }
      } catch (error) {
        console.log("microphone permission error:", error);
        reject(error);
      }
    });
  };

  const audioRecordingStart = async () => {
    try {
      const permission = await checkPermission();
      if (permission === "granted") {
        if (recorder === null) {
          await init();
          await recorder.start();
        } else {
          await recorder.start();
        }
        console.log("start recording");
      } else {
        alert("Please allow microphone");
      }
    } catch (error) {
      console.log("recording start error:", error);
    }
  };

  const audioRecordingStop = async () => {
    // onCheckConnection();
    try {
      if (recorder === null) {
        console.log("No recording in progress");
        return;
      }
      await recorder.stop();
      await new Promise((resolve) => setTimeout(resolve, voiceDurationInSeconds * 1000));
      sendVoiceEnd(messageLabel);
      console.log("stop recording");
    } catch (error) {
      console.log("recording stop error:", error);
    }
  };

  useEffect(() => {
    if (!isMount) setIsMount(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMount) {
      const browserAvailable = detectBrowserInfo();
      if (!browserAvailable) {
        return;
      }
      if ("MediaRecorder" in window) {
        // Open bug in Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1609427
        const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
        if (isFirefox) {
          init();
          return;
        }

        try {
          navigator.permissions
            .query({
              // @ts-ignore
              name: "microphone",
            })
            .then((result) =>
              result.addEventListener("change", async (event) => {
                if (
                  // @ts-ignore
                  event.target.state === "granted" &&
                  recorder === null
                ) {
                  await init();
                }
              }),
            );
          init();
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("The MediaRecorder API is not supported in your browser.");
      }
    } else {
      setIsMount(true);
    }
    // eslint-disable-next-line
  }, [isMount]);

  return {
    audioRecordingStart,
    audioRecordingStop,
  };
}

export const detectBrowserInfo = () => {
  // var nVer = navigator.appVersion;
  const nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = `${Number.parseFloat(navigator.appVersion)}`;
  let majorVersion = Number.parseInt(navigator.appVersion, 10);
  let nameOffset;
  let versionOffset;
  let ix;

  // In Opera, the true version is after "OPR" or after "Version"
  if ((versionOffset = nAgt.indexOf("OPR")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 4));
    if ((versionOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.slice(Math.max(0, versionOffset + 8));
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((versionOffset = nAgt.indexOf("Edg")) !== -1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 4));
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((versionOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 5));
  }
  // In Chrome, the true version is after "Chrome"
  else if ((versionOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 7));
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((versionOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 7));
    if ((versionOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.slice(Math.max(0, versionOffset + 8));
  }
  // In Firefox, the true version is after "Firefox"
  else if ((versionOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 8));
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (versionOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, versionOffset);
    fullVersion = nAgt.slice(Math.max(0, versionOffset + 1));
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.slice(0, Math.max(0, ix));
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.slice(0, Math.max(0, ix));

  majorVersion = Number.parseInt(`${fullVersion}`, 10);
  if (isNaN(majorVersion)) {
    fullVersion = `${Number.parseFloat(navigator.appVersion)}`;
    majorVersion = Number.parseInt(navigator.appVersion, 10);
  }
  switch (browserName) {
    case "Firefox": {
      if (majorVersion !== 46) {
        // alert(
        //   `Now your browswer info is ${navigator.userAgent}. To use all features, please install Firefox 46.0 and try it out.`,
        // );
        return true;
      }
      return true;
    }
    default: {
      return true;
    }
  }
};
