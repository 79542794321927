import React, { useEffect, useRef } from "react";
import { Card } from "@mui/material";
import "./styles.css";

const DotLoading = () => {
  const animation1 = useRef(null);
  const animation2 = useRef(null);
  const animation3 = useRef(null);
  const timeoutId = useRef(null);

  useEffect(() => {
    const animate = (element, delay) => {
      setTimeout(() => {
        element.style.opacity = 1;
        setTimeout(() => {
          element.style.opacity = 0;
        }, 500);
      }, delay);
    };

    const loop = () => {
      animate(animation1.current, 0);
      animate(animation2.current, 250);
      animate(animation3.current, 500);
      timeoutId.current = setTimeout(loop, 1500); // Restart the loop after all dots are done
    };

    loop(); // Start the animation loop

    return () => clearTimeout(timeoutId.current); // Cleanup the timeout
  }, []);

  return (
    <Card className="wrapper-for-dot">
      <div className="container" role="presentation">
        <div ref={animation1} className="dot" role="presentation-dot"></div>
        <div ref={animation2} className="dot" role="presentation-dot"></div>
        <div ref={animation3} className="dot" role="presentation-dot"></div>
      </div>
    </Card>
  );
};

export default DotLoading;
