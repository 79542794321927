
const styleClass = 'even:bg-white odd:bg-gray-300';
// const styleClass = '';
export const List = ({ component, render }: { component: any, render: (any) => any }) => {
    const children = component.children;
    const swapcolor = component?.swapcolor;

    return (
        <div style={{ ...component.ui_args?.web }}>
            {
                children.map((child, index) => {
                    return (
                        <div key={index} className={swapcolor ? styleClass : ''}>
                            {render(child)}
                        </div>
                    )
                })
            }
        </div>
    );
}
