
interface SegmentProps {
    departure_time: string,
    departure_next_day: string,
    departure_airport: string,
    departure_city: string,
    duration: string,
    arrival_time: string,
    arrival_next_day: string,
    arrival_airport: string,
    arrival_city: string,
    carrier: string,
    flight_number: string,
}

const style1 = "text-[14px] text-black font-medium";
const style2 = "text-[10px] text-gray-500";

export const FlightSegment = ({ args }: { args: SegmentProps }) => {
    if (!args)
        return <></>

    return (
        <div className="font-calibri min-w-max">
            <div className={`flex gap-[15px] ${style1}`}>
                <div className="flex">
                    <span>{args.departure_time}</span>
                    <span className={`text-[10px]`}>{args.departure_next_day}</span>
                </div>
                <div>{args.departure_airport}</div>
                <div>{args.departure_city}</div>
            </div>

            <div className={`${style2}`}>
                {args.duration}
            </div>

            <div className={`flex gap-[15px] ${style1}`}>
                <div className="flex">
                    <span>{args.arrival_time}</span>
                    <span className={`text-[10px]`}>{args.arrival_next_day}</span>
                </div>
                <div>{args.arrival_airport}</div>
                <div>{args.arrival_city}</div>
            </div>

            <div className={`${style2}`}>
                {`${args.carrier} ${args.flight_number}`}
            </div>
        </div>
    )
}