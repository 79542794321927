import React from "react";
import { DatePicker } from "antd";
import { convertDateTime, formatDateTime } from "../../utility";

interface DateRangeInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const DateRangeInput = (props: DateRangeInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  return (
    <DatePicker.RangePicker
      picker={component.granularity === "day" ? "date" : component.granularity}
      placeholder={component.placeholder}
      value={convertDateTime(data[component.id], component)}
      onChange={(date) => onChange(component, formatDateTime(date, component))}
      {...args}
      {...rest}
      className="h-9"
    />
  );
};
