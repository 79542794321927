import React, { useContext } from "react";
import { ImageBackground, ServerState } from "../../components";
import { ChatBox, MessageList, CartList } from "../../features";
import { SocketContext, ThemeContext } from "../../providers";

export const ChatScreen = () => {
  const { theme } = useContext(ThemeContext);
  const { isOnline } = useContext(SocketContext);
  return (
    <React.Fragment>
      <ImageBackground
        Children={
          <>
            <CartList />
            <MessageList theme={theme} isOnline={isOnline} />
            <ServerState />
            <ChatBox />
          </>
        }
      />
    </React.Fragment>
  );
};
