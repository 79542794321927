import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getOS, OS, getBrowser, Browser } from "@twin-shared";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

interface AppContextProps {
  readonly isChatBoxFocused: boolean;
  authToken: string | null;
  hasPermissions: boolean;
  os: OS | null;
  browser: Browser | null;
  requestPermissions: () => void;
  setIsChatBoxFocused: (val: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  isChatBoxFocused: false,
  authToken: null,
  hasPermissions: false,
  os: null,
  browser: null,
  requestPermissions: () => null,
  setIsChatBoxFocused: () => null,
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const authToken = useSelector((state: RootState) => state.app.authToken);
  const [isChatBoxFocused, setIsChatBoxFocused] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [os, setOs] = useState<OS | null>(null);
  const [browser, setBrowser] = useState<Browser | null>(null);

  useEffect(() => {
    setOs(getOS());
    setBrowser(getBrowser());
  }, []);

  const requestPermissions = async () => {};

  return (
    <AppContext.Provider
      value={{
        authToken,
        hasPermissions,
        requestPermissions,
        isChatBoxFocused,
        os,
        browser,
        setIsChatBoxFocused,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("AppContext must be used inside the AppProvider");
  }

  return context;
};
