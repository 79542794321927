import React from "react";
import { TimePicker } from "antd";
import { convertDateTime, formatDateTime } from "../../utility";

interface TimeRangeInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TimeRangeInput = (props: TimeRangeInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  const [from = '', to = ''] = data[component.id] || [];
  let timeFormat = ''+component.format;
  if (component.use12hours) {  
    timeFormat = component.format.replace('HH', 'hh');
    if (!component.format?.toLowerCase().includes('a')) {
        timeFormat= component.format.toLowerCase()+' A'
    }  
    
  }
  return (<TimePicker.RangePicker
      placeholder={component.placeholder}
      value={convertDateTime(data[component.id], component)}
      onChange={(time) => onChange(component, formatDateTime(time, component))}
      format={timeFormat}
      {...args}
      {...rest}
      use12Hours={component.use12hours} 
      className="h-9"
    />);
};
