import React from "react";

interface ImageProps {
  component: any;
}

export const Image = (props: ImageProps) => {
  const { component } = props;

  return (
    <img
      key={component.id}
      src={component.src}
      alt={component.alt}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      {...component.ui_args?.web}
    />
  );
};
