import React, { ReactNode, useContext, useMemo, useRef } from "react";
import { ThemeContext } from "../../providers";

interface ImageBackgroundProps {
  Children?: ReactNode | undefined;
}
export const ImageBackground = (props: ImageBackgroundProps) => {
  const imgRef = useRef();
  const {theme} = useContext(ThemeContext);

  const isMobile = useMemo(() => {
    return window.innerWidth < 640;
  }, [window.innerWidth]);

  return (
    <div className="h-screen w-full">
      <img
        alt="background image"
        ref={imgRef}
        className={`h-screen w-full`}
        src={isMobile ? theme.backgroundImage.tab : theme.backgroundImage.desktop}
        data-testid="background-image"
      />
      {props.Children}
    </div>
  );
};
