import {IMessage} from '../features/message/interfaces';

const defaultMapValue = (value: any, component: any) => value;

const getComponentValues: any = (
  component: any,
  mapValue: (value: any, component: any) => any,
) => {
  if (component.type.startsWith('input:')) {
    return {[component.id]: mapValue(component.default_value, component)};
  }

  if (component.type === 'wrapper:input_with_addons') {
    return {
      ...getComponentValues(component.children[0], mapValue),
      ...(component.before
        ? getComponentValues(component.before, mapValue)
        : {}),
      ...(component.after ? getComponentValues(component.after, mapValue) : {}),
    };
  }
  if (component.type.startsWith('wrapper:')) {
    return component.children.reduce((acc: any, child: any) => {
      return {...acc, ...getComponentValues(child, mapValue)};
    }, {});
  }

  return {};
};

export const getDefaultValues = (
  message: IMessage,
  mapValue = defaultMapValue,
) => getComponentValues(message.payload, mapValue);
