import React, { useRef } from "react";
import "./styles.css";


interface HtmlViewProps {
   htmlContent: any;
}
  
export const HtmlView = (props: HtmlViewProps) => {
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      if (iframeDocument) {
         const style = `
          html, body { 
            overflow: hidden;
          }
        `;
        const styleElement = iframeDocument.createElement("style");
        styleElement.textContent = style;
        iframeDocument.head.appendChild(styleElement);

        // Adjust iframe height based on content
        iframe.style.height = iframeDocument.body.scrollHeight + "px";
      }
    }
  };
  return (
    <div>
      <iframe
        ref={iframeRef}
        srcDoc={props.htmlContent}
        onLoad={handleIframeLoad}
        className="responsive-iframe" 
        data-testid="html-view"
      />
    </div>
  );
};
