import React from "react";
import { TimePicker } from "antd";
import { convertDateTime, formatDateTime } from "../../utility";
import dayjs from "dayjs";

interface TimeInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TimeInput = (props: TimeInputProps) => {
  const { data, component, onChange, args, ...rest } = props;
  let timeFormat = ''+component.format;
  if (component.use12hours) {  
    timeFormat = component.format.replace('HH', 'hh');
    if (!component.format?.toLowerCase().includes('a')) {
         timeFormat= timeFormat.toLowerCase()+' A'
    } 
  }

  const convertTimeValue = () => {
    let timeValue = data[component.id];
    if (timeValue) { 
      if (component.use12hours) {
        // Convert 24-hour format to 12-hour format with AM/PM
        if (timeValue.toLowerCase().includes('a') || timeValue.toLowerCase().includes('p')){
          return dayjs(timeValue, ['hh:mm:ss A', 'hh:mm A']).format('hh:mm:ss A');
        }
        return dayjs(timeValue, ['HH:mm:ss', 'HH:mm', 'hh:mm:ss A', 'hh:mm A']).format('hh:mm:ss A');
      } else {
        // Convert 12-hour format with AM/PM to 24-hour format
        return dayjs(timeValue, ['hh:mm:ss A', 'hh:mm A', 'HH:mm:ss', 'HH:mm']).format('HH:mm:ss');
      }
    } 
  };

  return (<TimePicker
      placeholder={component.placeholder}
      value={data[component.id] && dayjs(convertTimeValue(), timeFormat)}
      onChange={(time) => onChange(component, formatDateTime(time, component))}
      format={timeFormat}
      {...args}
      {...rest}
      use12Hours={component.use12hours}
      className="h-9"
    />);
};
