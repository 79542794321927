import React from "react";
import { Radio } from "antd";

interface RadioInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const RadioInput = (props: RadioInputProps) => {
  const { data, component, onChange,args , ...rest } = props;
  return (
    <Radio.Group
      value={data[component.id]}
      onChange={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
    >
      {component.options.map((option: any) => (
        <Radio key={option.value} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
