import { ReactComponent as FlightIcon } from "@twin-shared/lib/assets/flight.svg";
interface SummaryProps {
    arrival_airport: string,
    arrival_time: string,
    departure_airport: string
    departure_time: string
    duration: string
    next_day: string
    number_of_stops: string
    price: string
}

const style1 = "text-[14px] text-black font-medium";
const style2 = "text-[10px] text-gray-500";

const FlightImage = () => {
    return <FlightIcon className={'rotate-90 w-[12px]'}/>
}
export const FlightSummary = ({ args }: { args: SummaryProps }) => {
    if (!args)
        return <></>

    return (
        <div className="flex gap-[20px] items-center justify-between font-calibri min-w-max">
            <div className="flex-grow">
                <div className={`flex items-center justify-between ${style1}`}>
                    <span>{args.departure_time}</span>
                    <div className="flex">
                        <span>{args.arrival_time}</span>
                        <span className="text-[10px]">{args.next_day}</span>
                    </div>
                </div>

                <div className={`flex items-center justify-between ${style2} gap-[5px]`}>
                    <span>{args.departure_airport}</span>
                    <FlightImage />
                    <span>{args.duration}</span>
                    <FlightImage />
                    <span>{args.arrival_airport}</span>
                </div>
            </div>

            <div className="">
                <div className={`${style1}`}>{args.price}</div>
                <div className={`${style2}`}>{args.number_of_stops}</div>
            </div>
        </div>
    )
}