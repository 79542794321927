import React from "react";
import { Tooltip as AntdTooltip } from "antd";

interface TooltipProps {
  component: any;
  render: (child: any) => void;
}

export const Tooltip = (props: TooltipProps) => {
  const { component, render, ...rest } = props;
  return (
    <div
      style={{ width: "-webkit-fill-available" }}
      key={`div-${component.id}`}
    >
      <AntdTooltip
        key={component?.id}
        title={component?.title}
        trigger={component?.trigger}
        placement={component?.placement}
        {...component?.ui_args?.web}
      >
        {component.children.map((child: any) => render(child))}
      </AntdTooltip>
    </div>
  );
};
