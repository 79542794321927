import { Stationary } from "../Stationary";


export const YourCart = ({
  component,
}: {
  component: any;
  }) => {
  return (
    <Stationary iconname="ShoppingCart" component={component} />
  );
};
