import React from "react";
import { Input } from "antd";

interface PasswordInputProp {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const PasswordInput = (props: PasswordInputProp) => {
  const { data, component, onChange, args, ...rest } = props;

  return (
    <Input.Password
      placeholder={component.placeholder}
      value={data[component.id]}
      onChange={(e) => onChange(component, e.target.value)}
      {...args}
      {...rest}
      className="h-9"
    />
  );
};
