import {createSlice} from '@reduxjs/toolkit';

export interface ChatState {
  textChat: string;
}

const initialState: ChatState = {
  textChat: '',
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setTextChat: (state, action) => {
      state.textChat = action.payload;
    },
    clearTextChat: (state) => {
      state.textChat = '';
    },
  },
});

export const {setTextChat, clearTextChat} = chatSlice.actions;
export default chatSlice.reducer;
